import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import SyntaxHighlighter from 'react-syntax-highlighter'
import colorscheme from 'react-syntax-highlighter/dist/esm/styles/hljs/github-gist'

const Markdown = styled(ReactMarkdown)`
  font-size: 16px;
  line-height: 1.9;

  code {
    color: #444;
    border-radius: 4px;
    border: solid 1px #d6dde4;
    padding: .2em .4em;
    background-color: #f9f9f9;
    font-size: .85em;
    vertical-align: .08em;
  }

  li {
    margin: 0.4em 0;
  }

  pre > div {
    font-family: SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: .85em;
    line-height: 1.5;
    padding: 1em !important;
    background-color: #f9f9f9 !important;

    code {
      color: inherit;
      border-radius: 0;
      border: 0;
      font-size: 1em;
      padding: 0;
      background-color: transparent;
      vertical-align: inherit;
    }
  }

  table {
    border-collapse: collapse;
    width: auto;
    font-size: .85em;

    tr:nth-child(even) {
      background-color: #eee;
    }

    th, td {
      border: 1px solid;
      border-color: #ddd #ddd #ccc;
      padding: .5rem;
      min-width: 30px;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 2em;
  }

  > *:first-child {
    margin-top: 0 !important;
  }
`

const renderers = {
  a: ({ href, children }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">{children}</a>
  ),
  code({
    node,
    inline,
    className,
    children,
    ...props
  }) {
    const match = /language-(\w+)/.exec(className || '')

    return !inline && match ? (
      <SyntaxHighlighter
        style={colorscheme}
        language={match[1] || 'text'}
        PreTag="div"
        {...props}
      >
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    )
  },
}

const markdownRenderer = ({ markdown }) => (
  <Markdown
    components={renderers}
    remarkPlugins={[gfm]}
  >
    {markdown}
  </Markdown>
)

export default markdownRenderer
